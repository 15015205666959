<template>
  <div class="so-color container-fluid min-vh-100" style="background-color: var(--so-blue)">
    <Navigation />
    <div
      class="row justify-content-center align-items-center"
      style="min-height: calc(100vh - 65px)"
    >
      <div class="col">
        <div class="p-3 float-end bg-body rounded shadow">
          <router-link
            :to="{ name: 'Service' }"
            aria-current="page"
            active-class="active"
            class="btn btn-lg fs-1 link-dark"
          >
            Service
          </router-link>
        </div>
      </div>
      <div class="col">
        <div class="p-3 float-start bg-body rounded shadow">
          <router-link
            :to="{ name: 'Player' }"
            aria-current="page"
            active-class="active"
            class="btn btn-lg fs-1 link-dark"
          >
            Pizzaiolo
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navigation from "@/components/Navigation";
export default {
  name: "AppSelect",
  components: {
    Navigation,
  },
};
</script>
<style scoped>
.so-color {
  background-color: var(--so-blue) !important;
  color: black;
}
</style>
<style>
a, a:hover, a:focus, a:active { 
  color: #000000;
}
</style>

